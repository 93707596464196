import { useState, useEffect } from "react";
import styles from "../../styles/Settings.module.css";
import LoaderPrimary from "../../assets/Loader";
import Resolution from "./resolution";
import AspectRatio from "./aspect";
import Frame from "./frame";
import Orientation from "./orientation";
import CreateEventName from "./collection_name";
import { useFrameStore } from "../../function/Frame";
import { app } from "../../../config/firebase";
import { getFirestore, collection, getDocs, addDoc, query, where} from "firebase/firestore";
import { getStorage, ref, getDownloadURL} from "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function SettingsPage() {
    
    const db = getFirestore(app);
    const auth = getAuth();

    const storage = getStorage();

    const { addFrame, clearFrames } = useFrameStore();

    const [res, setRes ] = useState("1440x900");
    const [ aspectRatio, setAspectRatio] = useState("10/16");
    const [frameValue, setFrameValue ] = useState("None");
    const [ orientation, setOrientation] = useState("Landscape");
    const [eventName, setEventName] = useState("newEvent");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const settings = localStorage.getItem("settings");
        if(settings){
            setRes(settings.split(" ")[0]);
            setAspectRatio(settings.split(" ")[1]);
            setFrameValue(settings.split(" ")[2]);
            setOrientation(settings.split(" ")[3]);
            setEventName(settings.split(" ")[4]);
        }

    }, [])

    function saveSettings(e){
        e.preventDefault();
        setLoader(true)
        clearFrames();

        if(frameValue !== "None"){
            getDocs(collection(db, "frame")).then((snapShot) => {
                snapShot.forEach((doc) => {
                    if(doc.data().event === frameValue){
                        async function getFrame() {
                            try {
                                let userId = null;
                                await new Promise((resolve) => {
                                onAuthStateChanged(auth, (user) => {
                                    if (user) {
                                    userId = user.uid;
                                    } else {
                                    alert("User is not logged in. Please log in to upload.");
                                    resolve(false); 
                                    }
                                    resolve(true);
                                });
                                });

                                if (!userId) {
                                return false;
                                }

                                const createCollection = collection(db, eventName);

                                addDoc(createCollection, {
                                    event: eventName,
                                    userId: userId,
                                }).then((docRef) => {

                                    async function addDocumentIfUnique(collectionName) {
                                        try {
                                          // Check if a document with the same name already exists
                                          const q = query(collection(db, collectionName), where("name", "==", eventName));
                                          const querySnapshot = await getDocs(q);
                                      
                                          if (querySnapshot.empty) {
                                            // No document with the same name exists, so add the new document
                                            await addDoc(collection(db, collectionName), {
                                                name: eventName,
                                                docId: docRef.id,
                                                userId: userId,
                                            });
                                          }
                                        } catch (error) {
                                          console.error('Error adding document:', error);
                                        }
                                      }
                                    
                                    addDocumentIfUnique("My Collections");

                                    localStorage.setItem("settings", res + " " + aspectRatio + " " + frameValue + " " + orientation + " " + eventName + " " + docRef.id);
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                                const querySnapShot = await getDocs(collection(db, "frame", doc.id, frameValue));
                                getDownloadURL(ref(storage, querySnapShot.docs[0].id + ".png")).then((url) => {
                                                addFrame(url);
                                                setTimeout(() => {
                                                    window.location.href = "/";
                                                }, 1000);
                                            }).catch(err => alert(err));
                            } catch (error) {
                                alert(error);
                            }
                        }

                        getFrame();
                    }
                })
            }).catch(err => alert(err));
        }else{
            setTimeout(() => {
                window.location.href = "/";
            }, 1000);
        }
    }


    return (
        <div className={styles.container}>
            {
            loader? (<LoaderPrimary />) : (<form className={styles.form}>
                <div className={styles.settings_cont}>
                    <Resolution res={res} setRes={setRes} />
                    <AspectRatio aspectRatio={aspectRatio} setAspectRatio={setAspectRatio} />
                    <Frame frameValue={frameValue} setFrameValue={setFrameValue} />
                    <Orientation  orientation={orientation} setOrientation={setOrientation} />
                    <CreateEventName eventName={eventName} setEventName={setEventName} />
                </div>
                <button onClick={e => {saveSettings(e)}} className={styles.button}>Save</button>
            </form>)
        }
        </div>
    )
}