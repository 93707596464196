import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist } from "zustand/middleware";

export const useFrameStore = createWithEqualityFn(
    persist(
        (set, get) => ({
            frames : [],
            addFrame: (data) => set((state) => ({frames: [...state.frames, data]})),
            clearFrames: () => set({frames : []}),
            shallow
        }),
        {
            name : "frames",
        }
    )
)