import { useState } from "react";
import styles from "../../../styles/Email.module.css";
import KeyBoard from "../../../function/Keyboard";
import PostData from "../../../function/Send-Photos";
import { usePhotoStore } from "../../../function/Photos";
import LoaderPrimary from "../../../assets/Loader";

export default function EnterEmail(props) {

    const { photos, clearPhotos } = usePhotoStore();

    const [emailInput, setEmailInput] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

   function saveEmail(e) {
        e.preventDefault();
        if(emailRegex.test(emailInput)){
            setLoader(true);
            photos.forEach((photo, i) => {
                PostData(photo, emailInput).then((res) => {
                    if(i + 1 === photos.length){
                        if(res){
                            clearPhotos();
                            props.setView("camera");
                        }else{
                            alert("Sorry, we have an error saving your photo.");
                        }
                        setLoader(false);
                    }
                });
            })
        }else{
            setError("Please enter an invalid email address.")
        }
    }

    if(loader) {
        return <LoaderPrimary />
    }

    return (
        <div className={styles.email_container}>
            <form className={styles.form}>
                <div className={styles.input_container}>
                    <label className={styles.label} htmlFor="user-email">Enter Email</label>
                    <input className={styles.input} name="email" type="email" id="user-input" onChange={e => {setEmailInput(e.target.value)}} autoComplete="off"/>
                    <div className={styles.note_container}>
                        <p className={styles.note}>Note :</p>
                        <p className={styles.note_text}>Photos will be sent to the email you provided. No email will be saved to the database.</p>
                    </div>
                    {error && (<p className={styles.error_text}>{error}</p>)}
                </div>
                <button className={styles.enter_btn} onClick={e => {saveEmail(e)}}>Send</button>
            </form>
            <KeyBoard input={setEmailInput} />
        </div>
        
    )
}