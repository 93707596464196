import { useEffect, useState, useRef } from "react";
import styles from "../../../styles/StartCamera.module.css";
import Counter from "../Counter";
import LoaderPrimary from "../../../assets/Loader";
import { useFrameStore } from "../../../function/Frame";

export default function StartCamera( props ) {

    const { frames } = useFrameStore();

    const videoEl = useRef(null);

    const [loader, setLoader] = useState(true);
    const [takePhoto, setTakePhoto] = useState(false);
    const [orientation, setOrientation] = useState(getCurrentOrientation());
    const [videoPlaying, setVideoPlaying] = useState(false);

    const height = useRef(0);
    const width = useRef(0);
    const aspect = useRef(0);
    const stream = useRef(null);

    useEffect(() => {

        document.getElementById("video").style.filter = (props.videoFilter.filter)

    }, [props.videoFilter])

    useEffect(() => {

        const settings = localStorage.getItem("settings");

        let resWidth;
        let resHeight;
        let num;
        let den;

        if(settings){
            const resString = settings.split(" ")[0];
            resWidth = parseInt(resString.split("x")[0]);
            resHeight = parseInt(resString.split("x")[1]);
            const aspectString = settings.split(" ")[1];
            num = parseInt(aspectString.split(":")[0]);
            den = parseInt(aspectString.split(":")[1]);
        }else{
            resHeight = 1200;
            resWidth = 1920;
            num = 10;
            den = 16;
        }

        if(orientation === "Landscape"){
            height.current = resHeight;
            width.current = resWidth;
            aspect.current = num/den;
        }else{
            height.current = resWidth;
            width.current = resHeight;
            aspect.current = den/num;
        }

    }, [orientation])

    useEffect(() => {

        if (videoEl.current && !videoPlaying) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: width.current },
                    height: { ideal: height.current }, 
                    aspectRatio: aspect,
                    facingMode: "user"
                },
                audio: false
            })
            .then((mediaStream) => {
                if (!videoEl.current.srcObject) {
                    stream.current = mediaStream;
                    videoEl.current.srcObject = mediaStream;
                    
                    videoEl.current.onloadedmetadata = () => {
                        const playPromise = videoEl.current.play();
        
                        if (playPromise !== undefined) {
                            playPromise.then(() => {
                                setVideoPlaying(true);
                                setLoader(false);
                            }).catch((error) => {
                                console.log("Play error:", error);
                            });
                        }
                    };
                }
            })
            .catch((error) => {
                alert("Error accessing media devices:", error);
            });
        }
                            
    }, [videoEl, orientation, videoPlaying]);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerHeight < window.innerWidth){
                setOrientation("Landscape");
            }else{
                setOrientation("Portrait");
            }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []); 


    function handleTakePhoto(e) {
        e.preventDefault();
        setTakePhoto(true);
    }

    return (
        <div className={styles.take_photo_container} >
            <div className={`${loader ?"" : (styles.display_hide)}`}><LoaderPrimary /></div>
            <div className={`${styles.camera} ${loader ? (styles.display_hide) : ""}`} id="camera">
                <video className={`${props.isFullScreen? (styles.fullscreen_video) : (styles.video)}`} id="video" preload="none" ref={videoEl}></video>
                <img className={`${orientation === "landscape" ? styles.frame_landscape : styles.frame_orientation}`} src={frames[0]} alt="frame"/>
                <button className={`${styles.capture_btn} ${takePhoto ?(styles.display_hide) : ""}`} id="capture" onClick={(e) => {handleTakePhoto(e)}}>Take Photo</button>
                {takePhoto &&(<Counter videoFilter={props.videoFilter} setView={props.setView} orientation={orientation} stream={stream.current} />)}
            </div>
        </div>
    )
      
}

const getCurrentOrientation = () => {
        if(window.innerHeight < window.innerWidth){
            return "Landscape";
        }else{
            return "Portrait";
        }
    };