import { useEffect, useRef, useState } from "react";
import styles from "../../../styles/Preview.module.css";
import { usePhotoStore } from "../../../function/Photos";
import NextIcon from "../../../assets/Icons/next-icon.png";
import Counter from "../Counter";
import { useFrameStore } from "../../../function/Frame";
import "context-filter-polyfill";

export default function Preview(props) {

    const { frames } = useFrameStore();

    const { photos, addPhotos} = usePhotoStore();

    const [again, setAgain] = useState(false);
    const [index, setIndex] = useState(photos.length);
    const [hideIcon, setHideIcon] = useState("both");
    const [photo, setPhoto] = useState();

    const canvas = useRef(null);

    const aspect = useRef(0);
    const height = useRef(0);
    const width = useRef(0);
    const frame = useRef();

    useEffect(() => {
        const settings = localStorage.getItem("settings");

        let resWidth;
        let resHeight;
        let num;
        let den;
        frame.current = new Image();
        frame.current.crossOrigin = "anonymous";

        if(settings){
            const resString = settings.split(" ")[0];
            resWidth = parseInt(resString.split("x")[0]);
            resHeight = parseInt(resString.split("x")[1]);
            const aspectString = settings.split(" ")[1];
            num = parseInt(aspectString.split(":")[0]);
            den = parseInt(aspectString.split(":")[1]);
        }else{
            resHeight = 1200;
            resWidth = 1920;
            num = 10;
            den = 16;
        }

        if(props.orientation === "Landscape"){
            height.current = resHeight;
            width.current = resWidth;
            aspect.current = num/den;
            frame.current.src = frames[0];
        }else{
            height.current = resWidth;
            width.current = resHeight;
            aspect.current = den/num;
            frame.current.src = frames[0];
        }

    }, [props.orientation, frames])

    useEffect(() => {
        const video = document.getElementById("video");

        if(canvas.current){

            const context = canvas.current.getContext("2d");

            canvas.current.height = height.current;
            canvas.current.width = width.current ;

            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = "high";
            context.filter = (props.videoFilter.filter);
            context.drawImage(video, 0, 0, width.current , height.current);
            frame.current.onload = () => {
                context.filter =`none`;
                context.drawImage(frame.current, 0, 0, width.current , height.current);
                const data = canvas.current.toDataURL("image/png", 1.0);
                setPhoto(data);
            }    

        }
    },[canvas, props.videoFilter])

    useEffect(() => {
        if(photo){
            addPhotos(photo)
        }
    }, [photo, addPhotos])
    
    useEffect(()=> {
        if(index === 0 && photos.length > 1){
            setHideIcon("prev");
        }else if(index === photos.length -1 && photos.length > 1 ){
            setHideIcon("next");
        }else if(photos.length === 1){
            setHideIcon("both");
        }else{
            setHideIcon("");
        }
    }, [index, photos]);

    function handlePreviewIndex(event) {
        switch (event) {
            case "next":
                setIndex(index + 1)
                break;

            case "prev":
                setIndex(index - 1)
                break;
        
            default:
                break;
        }

    }

    function photoAgain(e) {
        e.preventDefault();
        setAgain(true)
    }

    async function photoFinish(e){
        e.preventDefault();
        await props.stream.getTracks().forEach(function(track) {
            track.stop();
        });
        props.setView("qrcode");
    }
    return (
        <>
            {again?( <Counter videoFilter={props.videoFilter} setView={props.setView} orientation={props.orientation} stream={props.stream} />) : (
                <>
                    <div className={`${styles.preview_container} ${props.orientation === "portrait"? styles.portrait : ""}`}>
                        <canvas className={styles.canvas} ref={canvas}></canvas>
                        {photos[index] && <img className={styles.photo} src={photos[index]} alt="Photobooth result." />}

                    </div>
                    <div className={`${hideIcon === "both" &&(styles.hide_icon)}`}>
                        <button className={`${styles.next_icon} ${hideIcon === "next" && (styles.hide_icon)}`} onClick={e => {e.preventDefault(); handlePreviewIndex("next")}}><img src={NextIcon}  alt="Next Icon"/></button>
                        <button className={`${styles.prev_icon} ${hideIcon === "prev" && (styles.hide_icon)}`} onClick={e => {e.preventDefault(); handlePreviewIndex("prev")}}><img src={NextIcon} alt="prev icon" /></button>
                    </div>
                    <div className={styles.button_container}>
                        <button className={styles.button} onClick={e => {photoAgain(e)}}>More</button>
                        <button className={styles.button} onClick={e => {photoFinish(e)}}>Finish</button>
                    </div>
                </>)
            }
        </>
    )
}