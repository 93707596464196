import { getAuth, signOut } from "firebase/auth";
import { app } from "../../../config/firebase";

export default function Logout() {
    const auth = getAuth(app);

    signOut(auth).then(() => {
        window.location.href = "/";
    }).catch((error) => {
        alert(error)
    })
}