import { collection, addDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadString} from "firebase/storage";
import { app } from "../../../config/firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default async function PostData(photo, fileName, permission, eventName) {
  const db = getFirestore(app);
  const storage = getStorage();
  const auth = getAuth();

  try {
    let userId = null;
    await new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          userId = user.uid;
        } else {
          alert("User is not logged in. Please log in to upload.");
          resolve(false); 
        }
        resolve(true);
      });
    });

    if (!userId) {
      return false;
    }

    const addRes = await addDoc(collection(db, eventName), {
      event: eventName,
      picId: fileName,
      userId: userId,
      notPermitted: permission
    });

    if (addRes) {
      const storageRef = ref(storage, addRes.id);
      const res = await uploadString(storageRef, photo, "data_url");

      if (res) {
        return true;
      } else {
        alert("Error uploading image to storage:", res);
        return false;
      }
    } else {
      alert("Error adding document to Firestore:", addRes);
      return false;
    }
  } catch (error) {
    alert("Error in PostData function:", error);
    if (error.code === 'failed-precondition' || error.code === 'unavailable') {
      alert("Internet connection error. Please check your connection and try again.");
    }
    return false;
  }
}
  