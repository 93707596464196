import styles from "../../styles/Settings.module.css";

export default function AspectRatio(props) {
    return (
        <div className={styles.input_cont}>
            <label htmlFor="aspect" className={styles.label}>Aspect Ratio : </label>
            <select id="aspect" value={props.aspectRatio} onChange={e => {props.setAspectRatio(e.target.value)}} className={styles.select}>
                <option value="1:1">1:1</option>
                <option value="2:3">2:3</option>
                <option value="3:4">3:4</option>
                <option value="4:5">4:5</option>
                <option value="10:16">10:16</option>
                <option value="9:16">9:16</option>
            </select>
        </div>
    )
}