import Login from "../../function/Login";
import { useState } from "react";
import styles from "../../styles/Loginform.module.css";

export default function LoginForm() {
    const [email, setEmail] = useState ("");
    const [password, setPassword] = useState("");

    return (
        <div className={styles.container} >
            <form className={styles.form}>
                <div className={styles.input_container}>
                    <label className={styles.label} htmlFor="email">Email</label>
                    <input className={styles.input} type="email" name="email" id="email" autoComplete="email" onChange={e => {
                        setEmail(e.target.value)
                    }}/>
                </div>
                <div className={styles.input_container}>
                    <label className={styles.label} htmlFor="password">Password</label>
                    <input className={styles.input} type="password" name="password" id="password" autoComplete="current-password" onChange={e => {
                        setPassword(e.target.value)
                    }}/>
                </div>
                <button className={styles.button} onClick={e => {e.preventDefault(); Login(email, password)}}>Submit</button>
            </form>
        </div>
    )
}