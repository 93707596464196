import styles from "../../styles/Settings.module.css";

export default function Resolution(props) {
    return (
        <div className={styles.input_cont}>
            <label htmlFor="resolution" className={styles.label}>Resolution : </label>
            <select id="resolution" value={props.res} onChange={e => {props.setRes(e.target.value)}} className={styles.select}>
                <option value="1080x1350">1080x1350 (WXGA+)</option>
                <option value="1440x900">1440x900 (WXGA+)</option>
                <option value="1680x1050">1680x1050 (WSXGA+)</option>
                <option value="1920x1080">1920x1080 (Full HD)</option>
                <option value="1920x1200">1920x1200 (WUXGA)</option>
                <option value="2360x1640">2360x1640 (iPad)</option>
                <option value="2560x1440">2560x1440 (QHD)</option>
                <option value="2560x1600">2560x1600 (WQXGA)</option>
                <option value="3200x1800">3200x1800 (QHD+)</option>
                <option value="3840x2160">3840x2160 (4K UHD)</option>
                <option value="3840x2400">3840x2400 (WQUXGA)</option>
            </select>
        </div>
    )
}