import { useState, useEffect, useMemo } from "react";
import styles from "../../../styles/Counter.module.css";
import Preview from "../Preview";

export default function Counter(props) {

    const [timer, setTimer] = useState("");
    const [index, setIndex] = useState(0);
    const [timerEnd, setTimerEnd] = useState(false);

    const timerArray = useMemo(() => {
        return ["Get Ready", "3", "2", "1", "Say Cheese!"];
    }, []);

    useEffect(() => {

        function getTimer() {
            setTimer(timerArray[index])
            setIndex(index + 1)
        }

        if(index < timerArray.length){
            const interval = setInterval(() => getTimer(), 1000);
        
            return () => clearInterval(interval);
        }else{
            setTimeout(() => {
                setTimerEnd(true);
            }, 2000);
        }
    }, [index, timerArray])
    
    return (
        <>
            {timerEnd? (<Preview videoFilter={props.videoFilter} setView={props.setView} orientation={props.orientation} stream={props.stream}/>) : 
            (<div className={styles.counter_container}>
                {<h4 className={`${styles.h4}  ${index < timerArray.length? (styles.timer) : (styles.cheese)}`}>{timer}</h4>}
            </div>)}
        </>
    )
}