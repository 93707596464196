import styles from "../../styles/Settings.module.css";

export default function Orientation(props) {
    return (
        <div className={styles.input_cont}>
            <label htmlFor="orientation" className={styles.label}>Orientation : </label>
            <select id="orientation" value={props.orientation} onChange={e => {props.setOrientation(e.target.value)}} className={styles.select}>
                <option value="Landscape">Landscape</option>
                <option value="Portrait">Portrait</option>
            </select>
        </div>
    )
}