import { app } from "../../../config/firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function Login(email, password) {
    const auth = getAuth(app);

    signInWithEmailAndPassword(auth, email, password).then((cred) => {
        //
    }).catch((error) => {
        alert(error.message)
    })
}