import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import styles from "../../styles/Outlet.module.css";

function Layout (props) {
    return (
        <div>
            <Header user={props.user} />
            <div className={styles.outlet}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default Layout;