import styles from "../../../styles/Header.module.css";
import { NavLink, useLocation } from "react-router-dom";
import Logout from "../../../function/Logout";

export default function Header(props) {
    const user = props.user;

    const loc = useLocation();

    return (
        <header className={styles.header} >
            <div className={styles.background}></div>
            {loc.pathname.split("/")[1] !== "download" &&(<NavLink to={"/"} className={`${styles.nav} ${loc.pathname === "/"? styles.active: ""}`}>Home</NavLink>)}
            {user &&( <button onClick={e => {e.preventDefault(); Logout()}} className={styles.button} >Log Out</button>
            )}
        </header>
    )
}