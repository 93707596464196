import styles from "../../styles/Settings.module.css";

export default function CreateEventName(props) {

    function SaveName(e) {
        const name = e.replaceAll(" ", "");
        props.setEventName(name);

    }
    
    return (
        <div className={styles.input_cont}>
            <label htmlFor="collection-name" className={styles.label}>Event Name :</label>
            <input id="collection-name" value={props.eventName} onChange={e => {SaveName(e.target.value)}}  type="text" className={styles.select} />
        </div>
    )
}