import styles from "../../styles/Home.module.css";
import { Link } from "react-router-dom";
import LoginForm from "../Login";

export default function HomePage (props) {
    const user = props.user;
    return(
        <>
            {user? (<div className={styles.container}>
                <Link to={"photobooth"} className={styles.button}>Start Photo Booth</Link>
                <Link to={"settings"} className={styles.button}>Settings</Link>
            </div>) :  (<LoginForm />)}
        </>
    )
}