import { useEffect, useState, useRef} from "react";
import LoaderPrimary from "../../assets/Loader";
import { app } from "../../../config/firebase";
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { getDownloadURL, ref, getStorage  } from "firebase/storage";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import styles from "../../styles/Download.module.css";
import { usePhotoStore } from "../../function/Photos";
import DownloadIcon from "../../assets/Icons/download.png";

export default function Gallery() {
  const db = getFirestore(app);
  const storage = getStorage();
  const auth = getAuth();

  const { photos, addPhotos} = usePhotoStore();

  const [loader, setLoader ] = useState(true);
  const [colName, setColName] = useState("");

  const myCollections = useRef([]);

  useEffect(() => {
   async function getCollections() {
    try {
      let userId = null;
          await new Promise((resolve) => {
          onAuthStateChanged(auth, (user) => {
              if (user) {
              userId = user.uid;
              } else {
              alert("User is not logged in. Please log in to upload.");
              resolve(false); 
              }
              resolve(true);
          });
          });

          if (!userId) {
          return false;
          }

          const collectionRef = collection(db, "My Collections");
    
          const q = query(collectionRef, where("userId", "==", userId));

          getDocs(q).then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                myCollections.current.push(doc.data());
              });
            })
            .catch((error) => {
              console.error("Error getting documents: ", error);
            }).finally(() => {
              setLoader(false);
            });
      
    } catch (error) {
      
    }
   }

   getCollections();
  },[auth, db])

  function viewPhotos(e) {
      e.preventDefault();
      if(colName){
        setLoader(true);
      
          // Get all documents from the collection
          getDocs(collection(db, colName))
              .then((snapShot) => {
                if (snapShot.empty) {
                    alert("No photos found in this collection.");
                    setLoader(false);
                } else {
                    // Filter documents based on eventName and picId
                    const matchingDocs = snapShot.docs.filter(doc => {
                    return doc.data().notPermitted === false;
                    });
            
                    if (matchingDocs.length === 0) {
                    alert("No photos found in this collection.");
                    setLoader(false);
                    return; // Exit the function if no matches
                    }
            
                    // Process matching documents
                    matchingDocs.forEach((doc) => {
                    getDownloadURL(ref(storage, doc.id))
                        .then((url) => {
                        addPhotos(url);
                        })
                        .catch((error) => {
                          alert(error.code)
                        })
                        .finally(() => {
                        setLoader(false);
                        });
                    });
                }
          });
      }else{
        alert("Choose a collection.")
      }
  }

  function DownloadPhotos(e, url){
      e.preventDefault();
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
          const blob = xhr.response;
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          const newUrl = new URL(url);
          const pathname = newUrl.pathname.split("/")[5];
          link.href = blobUrl;
          link.setAttribute("download", `${pathname}.jpeg`);
          document.body.appendChild(link);
          link.click();
              document.body.removeChild(link);

      };
      xhr.open('GET', url);
      xhr.send();
  }

  if(loader){
      return <div className={styles.div_cont}>
                <LoaderPrimary />
            </div>
  }

  return (
      <>
          <div className={styles.div_cont}>
            <select id="collections" value={colName} className={styles.collections} onChange={e => {setColName(e.target.value)}} >
                  <option value="">Choose a Collection</option>
                  {myCollections.current.map((list, i) => {
                      return <option key={i} value={list.name}>{list.name}</option>
                  })}
            </select>
            <button className={styles.collections_btn} onClick={e => {viewPhotos(e)}}>Search</button>
          </div>
          <div className={styles.download_container}>
                <div className={styles.photos_cont}>
                            {photos.map((photo, i) => (
                            <li className={styles.li} key={i}>
                                <button type="button" className={styles.download_button} onClick={e => {DownloadPhotos(e, photo)}}><img src={DownloadIcon} alt="download" className={styles.download_icon} /></button>
                                <img src={photo} alt="photobooth result" className={styles.photo} />
                            </li>))}
                </div>
            </div>
      </>
  )
}