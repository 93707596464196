import styles from "../../../styles/Color_Settings.module.css";
import { useState } from "react";

export default function ColorSettings(props) {

    const [brightness, setBrightness] = useState(getBrightness());
    const [contrast, setContrast] = useState(getContrast());
    const [saturate, setSaturate] = useState(getSaturate());

    function filterChange(e) {
        switch (e.id) {
            case "brightness":
                setBrightness(e.value);
                sessionStorage.setItem("brightness", e.value);
                sessionStorage.setItem("filter", `brightness(${e.value}%) contrast(${contrast}%) saturate(${saturate}%)`);
                props.setFilter(`brightness(${e.value}%) contrast(${contrast}%) saturate(${saturate}%)`)
                break;
            case "contrast":
                setContrast(e.value);
                sessionStorage.setItem("contrast", e.value);
                sessionStorage.setItem("filter", `brightness(${brightness}%) contrast(${e.value}%) saturate(${saturate}%)`);
                props.setFilter(`brightness(${brightness}%) contrast(${e.value}%) saturate(${saturate}%)`)
                break;
            case "saturate":
                setSaturate(e.value);
                sessionStorage.setItem("saturate", e.value);
                sessionStorage.setItem("filter", `brightness(${brightness}%) contrast(${contrast}%) saturate(${e.value}%)`);
                props.setFilter(`brightness(${brightness}%) contrast(${contrast}%) saturate(${e.value}%)`)
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.settings_cont}>
            <div className={styles.brightness_cont}>
                <label htmlFor="brightness">
                    <h4>Brightness</h4>
                </label>
                <input type="range" value={brightness} id="brightness" name="brightness" min="0" max="200" onChange={e => {filterChange(e.target)}}/>
                <h4>{brightness}%</h4>
            </div>
            <div className={styles.brightness_cont}>
                <label htmlFor="contrast">
                    <h4>Contrast</h4>
                </label>
                <input type="range" value={contrast} id="contrast" name="contrast" min="0" max="200" onChange={e => {filterChange(e.target)}}/>
                <h4>{contrast}%</h4>
            </div>
            <div className={styles.brightness_cont}>
                <label htmlFor="saturate">
                    <h4>Saturate</h4>
                </label>
                <input type="range" value={saturate} id="saturate" name="saturate" min="0" max="200" onChange={e => {filterChange(e.target)}}/>
                <h4>{saturate}%</h4>
            </div>
        </div>
    )
}

function getBrightness() {
    const brightness = sessionStorage.getItem("brightness");

    if(brightness){
        return parseInt(brightness);
    }else{
        return 100;
    }
}

function getContrast() {
    const contrast = sessionStorage.getItem("contrast");

    if(contrast){
        return parseInt(contrast);
    }else{
        return 100;
    }
}

function getSaturate() {
    const saturate = sessionStorage.getItem("saturate");

    if(saturate){
        return parseInt(saturate);
    }else{
        return 100;
    }
}